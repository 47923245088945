<template>
  <div class="layout-padding">
    <div class="layout-padding-auto layout-padding-view">

      <el-table :data="data.tableData" style="width: 100%">
        <el-table-column prop="landInfo.title" label="标题" show-overflow-tooltip >
          <template #default="scope">
            <span style="cursor: pointer;" @click="toDetail(scope.row.landInfo)" >{{scope.row.landInfo.title}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="resourceNo" label="资源编号"   show-overflow-tooltip />
        <el-table-column prop="landInfo.regionId" label="所属行政区" width="120" show-overflow-tooltip>
          <template #default="scope">
            {{ findLabel(land_area, scope.row.landInfo.regionId) }}
          </template>
        </el-table-column>
        <el-table-column prop="startPrice" label="起始价" width="130" show-overflow-tooltip>
          <template #default="scope"> {{ scope.row.startPrice || 0 }} 万元 </template>
        </el-table-column>
        <el-table-column prop="currentPrice" label="当前价" width="130" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row.currentPrice || scope.row.startPrice|| 0 }} 万元
          </template>
        </el-table-column>
        <el-table-column prop="buyerCount" label="竞价人数" width="80" show-overflow-tooltip />

        <el-table-column label="操作" width="130">
          <template #default="scope">
            <el-button text type="primary"  @click="onSnedApply(scope.row)" v-if="!isEnd(scope.row)">拍卖申请</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="user-info-list-page">
        <el-pagination background layout="prev, pager, next" :page-count="data.totalPage" :current-page="data.currentPage" @current-change="pageChange">
        </el-pagination>
      </div>
    </div>

    <!-- 编辑、新增  -->
<!--    <form-dialog ref="formDialogRef" @refresh="getDataList(false)" />-->
    <agreement-trade-files-dialog ref="agreementTradeFilesDialogRef" @refresh="pageChange" />

  </div>
</template>

<script setup  name="biddingApply">
import {reactive, ref, defineAsyncComponent, onMounted} from 'vue'
import { getBargainListWithLand } from '../../service/CommonService';
import {Message, MessageBox} from 'element-ui'
import { useDict, findLabel} from '@/hooks/dict';

import CommonService from "@/service/CommonService";
import {useRouter} from "vue-router/composables";

const router = useRouter();

// // 引入组件
// const FormDialog = defineAsyncComponent(() => import('./form.vue'));
//
const AgreementTradeFilesDialog = defineAsyncComponent(() => import('@/components/AuctionTradeFiles.vue'));
// // 定义查询字典

const { transaction_way, land_area, land_purpose, land_owner_type, transaction_person_type } = useDict(
    'transaction_way',
    'land_area',
    'land_purpose',
    'land_owner_type',
    'transaction_person_type'
);

// 定义变量内容
// const formDialogRef = ref();
//
// const fileDialogRef = ref();
const agreementTradeFilesDialogRef = ref();

const isEnd = (row) =>{
  let date = new Date()
  return row.applyEndTime < date.getTime();
}

//
const data = reactive({
  pageSize:10,
  currentPage:1,
  totalPage:1,
  tableData: [
  ]
})

const showApply = (row) => {
  let time = new Date().getTime();
  return time > row.applyStartTime && time < row.applyEndTime  && row.transactionStatus === "processing";
} ;

const pageChange = (page) =>{
  loadData(page);
}
const defaultLandQueryParams = ref({
  transactionWay: 'listing', //交易方式=挂牌
  current: 1

});
onMounted(() => {
  loadData(1);
})
const loadData = (page) =>{
  defaultLandQueryParams.value.current = page
  CommonService.getBargainListWithLand(defaultLandQueryParams.value).then((res)=>{
    if(res.code === 0){
      for (let i=0;i<res.data.records.length;i++) {
        if (res.data.records[i].landInfo != undefined) {
          console.log(res.data.records[i].landInfo.title)
          res.data.records[i].landInfo.title = res.data.records[i].landInfo.title.replace("出租出租", '出租')
          res.data.records[i].landInfo.title = res.data.records[i].landInfo.title.replace("转让转让", '转让')
        }
      }
      data.tableData = res.data.records;
    }
  })
}


const onSnedApply = async (params) => {

  agreementTradeFilesDialogRef.value.openDialog(params, 'auction');
};
const   toDetail=(landInfo)=>{
  if(landInfo.auctionInfo &&  landInfo.transactionWay === "listing"){
    router.push("/trade/gp/"+this.landInfo.auctionInfo.id);
  }
  else if( landInfo.transactionWay === "auction" && landInfo.auctionInfo){
    router.push("/land/auction/"+landInfo.id);
  }
  else{
    router.push("/land/detail/"+landInfo.id);
  }
}

</script>
